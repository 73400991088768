module.exports = {
	message: {
		bottom: {
			language: "中",
			about: "About us",
			agreement1: "User agreement",
			agreement2: "Privacy agreement",
			secure: "Dating safety",
			help: "Help center",
			contact: "Contact us",
		},
		navigation: {
			language: "中",
			home: "home",
			post: "post",
			gift: "gift",
			upgrades: "Upgrades",
			topup: "top-up",
			homepage: "My home page",
			set: "Personal Settings",
			invite: "Invite friends",
			logout: "Log out",
			nologged: "Not logged in",
			search: "search",
			input: 'Please enter your username'
		},
		login: {
			welcome: "Welcome to use",
			introduce: "introduce",
			welcomelogin: "Welcome to login",
			nohave: "Don't have an account yet?",
			register: "Go to Register",
			username: "Please enter your email or username",
			password: "Please enter password",
			remember: "Remember the password",
			forgot: "Forgot password",
			login: "Log in",
		},
		signin: {
			fillreset: "Fill in the registration information",
			upload: "Upload profile picture",
			information: "Fill in the basic information",
			email: "mailbox",
			email2: "Please enter your usual email address",
			nickname: "nickname",
			name: 'Please enter a nickname (limit 2-8 characters)',
			setpassword: "Set a password",
			password: "Please enter your password (6-15 characters, case sensitive)",
			confirmpassword: "Confirm password",
			nextpassword: "Please enter your password again",
			pwd: "Password inconsistency",
			back: "Back",
			laststep: "Last step",
			next: "next",
			photo: "Please upload photo",
			sex: "sex",
			Boy: "Boy student",
			schoolgirl: "schoolgirl",
			localarea: "Local area",
			nation: "nation",
			province: "province",
			city: "The city",
			oneself: "Introduce oneself",
			yourself: "Please enter content to introduce yourself",
		},
		reset: {
			reset: "Reset password",
			email: "mailbox",
			email2: "Please enter your usual email address",
			setpassword: "Set a password",
			password: "Please enter your password (6-15 characters, case sensitive)",
			confirmpassword: "Confirm password",
			nextpassword: "Please enter your password again",
			OK: "OK",
		},
		pop: {
			service: "Customer service",
			send: "send",
			inputtext: "Input text",
			online: "online",
			Offline: "Offline",
			profile: "Personal profile",
			cleartext: "Are you sure you want to clear your chat history?",
			received: "Likes received",
			liked: "I liked it",
			lv: "Lv.",
			EXP: "EXP",
			share: "Share",
			forward: "From: Forward",
			website: "From: Website",
			picture: "picture",
			photo: "Photo",
			dynamics: "Dynamic state",
			TRANSLATORS: "TRANSLATORS",
			forward2: "Are you sure to forward this post?",
			delete: "Are you sure to delete this post?",
			delete2: "Are you sure to delete this image?",
			send2: "Post your comments",
			from: "Be from",
			tag: "tag",
			personal: "Personal data",
			emote: "emote",
			video: "video",
			TA: "TA",
			untranslate: "untranslate",
			text1: "Objectionable content is content that contains pornography, violence, advertising, or other content that disturbs your normal work life",
			text2: "Information you would like to report:",
			text3: "Fill in the reason you want to report:",
			text4: "Please feel free to fill in, we will protect your privacy",
			fans: "My fans",
		},
		btn: {
			chat: "chat",
			clear: "Clear",
			verify: "OK",
			cancel: "Cancel",
			clearCon: "Clear content",
			prompt: "No prompt",
			uncheck: "uncheck",
			close: "close",
			report: "report",
			publish: "publish",
			add: "add",
			delete: "delete",
			upload: 'UPLOAD',
			submit: 'submit'
		},
		home: {
			vermicelli: "vermicelli",
			dynamic: "Dynamic state",
			collect: "Collect",
			message: "chat",
			visitor: "Visitor log",
			service: "Contact customer service",
			system: "System message",
			follow: "follow",
			like: "like",
			me: "@ Me",
			sendPost: "post",
			ordinary: "Ordinary member",
			diamond: "Diamond member",
			permanent: "Permanent member",
		},
		index: {
			all: "All",
			recommended: "Recommended",
			send: "SendTA",
			aite: "@TA",
			direct: "chat",
		},
		post: {
			emote: "emote",
			video: "video",
			picture: "picture",
			ta: "TA",
			untranslate: "untranslate",
		},
		gift: {
			total: "Total gift",
			mygift: "My gift",
			all: "All",
			virtual: "Virtual gift",
			accessory: "accessory",
			doll: "doll",
			flower: "Fresh flower",
			watch: "watch",
			details: "details",
			inventory: "inventory",
			specification: "specification",
			buy: "Buy",
			text: "Please enter a message",
			quantity: "quantity",
			anonymous: "Anonymous gift",
			open: "Open gift",
			private: "Private gift",
			desc: "intro"
		},
		upgrades: {
			membership: "Upgrade membership",
			section: "Partial function",
			full: "Full function",
			text: "You are already a permanent member!",
			successfully: "Upgrade successfully~",
			text2: "Please recharge before you have enough gold coins~",
			text3: "Are you sure to upgrade your membership?",
			text4: "Top Up Now",
			permanent: "permanent",
		},
		topup: {
			coin: "Top up gold coin",
			amount: "amount",
			other: "Other amount",
			information: "Recharge information",
			text: "Please enter another amount",
			text2: "Recharge record/gift record",
			serial: "Serial number",
			payee: "payee",
			recharge: "Recharge method",
			time: " time",
			status: "status",
		},
		setting: {
			information: "Basic information",
			translation: "Buy a translation pack",
			privacy: "Privacy Settings",
			notification: "notification",
			blacklist: "User blacklist",
			security: "Account security",
			invitation: "Email invitation",
			invitation2: "Link invitation",
			avatar: "avatar",
			loginemail: "Login email",
			areaname: "Area name",
			addtag: "Add tag",
			unmodifiable: "unmodifiable",
			package: "Translation package",
			remaining: "Remaining",
			character: "character",
			text1: "Select Translation Package (characters) ",
			text2: "Who can comment on my post",
			text3: "Anyone can like my post",
			text4: "Who can bookmark my posts",
			text5: "Everyone (excluding blacklisted users)",
			text6: "People I pay attention to",
			text7: "Receiving system notification emails: When receiving @, comment, private message reminder, set whether to receive notification emails from the system and the receiving period",
			alluding: "Alluding to me",
			review: "review",
			letter: "Personal letter",
			reminder: "Email reminder",
			receive: "Receive system mail",
			receive2: "Do not receive system mail",
			never: "Never receive",
			daily: "Receive daily",
			weekly: "Weekly reception",
			monthly: "Monthly receipt",
			shift: "Shift out",
			text8: "Blacklisted users will not be able to follow you, comment on you or view your personal space. If you already follow them, they will automatically disengage.",
			addblacklist: "Add blacklist",
			changepassword: "Change password",
			bound: "Bound mailbox",
			original: "Original code",
			newpwd: "New password",
			text9: "You can invite your friends by email, and your friends will get 50 experience points when they successfully register",
			confirm: "Confirm",
			invitation3: "Invitation",
			text10: "Please enter email"
		},
		othor: {
			online: "online",
			Offline: "Offline",
			gift: 'gift',
			giftHer: 'Be presented to',
			immediately: 'Give away immediately',
			Allread: 'All read',
			unread: 'unread',
			read: 'read',
			text: 'What is new to tell you~',
			input: 'Please enter',
			trend: 'Dynamic details',
			system: 'message',
			myname: 'mine',
			text: 'My gold coin',
			btnall: 'Common function',
			save: 'save',
			mytrends: 'My dynamic',
			mysave: 'My collection',
			mylove: 'My attention',
			member: 'member',
			record: 'Recharge record',
			Purchase: 'Purchase record',
			coin: 'Gold coin',
			Buynow: 'Buy now',
			Invitefriends: 'Invite friends',
			Copylink: 'Copy link',
			completed: 'completed',
			Blacklist: 'Blacklist',
			text2: 'Please select the amount',
			text3: 'Please complete the top-up information',
			text4: 'To be reviewed',
			text5: 'Turn down',
			Liked: 'Liked',
			published: 'published',
			my: 'me',
			my2: 'me',
			text6: 'Copy link',
			text7: 'Membership only for you to pick up',
			text8: 'Gift mall',
			text9: 'Choose a gift for your friend',
			text10: 'receiver',
			mount: 'total',
			type: 'type',
			money: 'top-up',
			gomoney: 'Top up immediately',
			number: 'Other quantity',
			realname: 'Real name',
			inputName: 'Please enter your real name',
			realnum: 'Real phone number',
			inputNum: 'Please enter your real phone number',
			realEmial: 'Real mailbox',
			inputEmial: 'Please enter your real email address',
			butText: 'Purchase information',
			login1: 'One world meets',
			and: 'with',
			you: 'you',
			sigin: 'Register now',
			read2: 'I have read and agree',
			login2: 'From the other side of the world',
			sigintit: 'Sign in',
			login3: 'acquaintance',
			login4: 'hereon',
			login5: 'I have an account',
			loginbtn: 'Sign in now',
			end: 'complete',
			update: 'Confirm modification',
			memberdesc: 'You are currently',
			userinfo: 'Personal information',
			userDesc: 'Please enter a personal profile~',
			chatTit: 'chatting',
			chatTitlist: 'Chat history',
			tishi: 'Please enter valid content',
			sendText: 'Gift record',
			sendper: 'Please enter the giver',
			buyText: 'Can be viewed in the purchase history',
			buySucc: 'Successful purchase',
			select: 'Select',
			selected: 'Selected',
			page: 'piece',
			agreeBtn: 'Please tick Protocol',
			senduser: 'Complimentary subscriber',
			code: 'Please enter the verification code',
			getCode: 'Verification code',
			sosoText: 'Release success Please wait for review',
			succTrends: 'Successful forwarding',
			total: '',
			article: 'in total',
			getmore: 'See more',
			usdt:' Submitted please wait for review ',
			upload:' Upload payment voucher ',
			address:' address',
			chinaText:'No Chinese input'
		}
	},
};