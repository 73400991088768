module.exports = {
	message: {
		bottom: {
			language: "EN",
			about: "關于我們",
			agreement1: "用戶協議",
			agreement2: "隱私協議",
			secure: "交友安全",
			help: "幫助中心",
			contact: "聯系我們",
		},
		navigation: {
			language: "EN",
			home: "首頁",
			post: "帖子",
			gift: "禮物",
			upgrades: "升級",
			topup: "充值",
			homepage: "我的主頁",
			set: "個人設置",
			invite: "邀請好友",
			logout: "退出登錄",
			nologged: "未登錄",
			search: "搜索",
			input: '請輸入用戶名'
		},
		login: {
			welcome: "歡迎使用",
			introduce: "介紹",
			welcomelogin: "歡迎登錄",
			nohave: "還沒有賬號？",
			register: "去注冊",
			username: "請輸入郵箱或用戶名",
			password: "請輸入密碼",
			remember: "記住密碼",
			forgot: "忘記密碼",
			login: "登錄",
		},
		signin: {
			fillreset: "填寫注冊信息",
			upload: "上傳頭像",
			information: "填寫基本信息",
			email: "郵箱",
			email2: "請輸入常用郵箱",
			nickname: "昵稱",
			name: '請輸入昵稱(限2-8個字)',
			setpassword: "設置密碼",
			password: "請輸入密碼(6-15個字符，區分大小寫)",
			confirmpassword: "確認密碼",
			nextpassword: "請再次輸入密碼",
			pwd: "密碼不壹致",
			back: "返回",
			laststep: "上壹步",
			next: "下壹步",
			photo: "請上傳頭像",
			sex: "性別",
			Boy: "男生",
			schoolgirl: "女生",
			localarea: "所在地區",
			nation: "國家",
			province: "省",
			city: "市",
			oneself: "介紹自己",
			yourself: "請輸入內容介紹自己",
		},
		reset: {
			reset: "重置密碼",
			email: "郵箱",
			email2: "請輸入常用郵箱",
			setpassword: "設置密碼",
			password: "請輸入密碼(6-15個字符，區分大小寫)",
			confirmpassword: "確認密碼",
			nextpassword: "請再次輸入密碼",
			OK: "確定",
		},
		pop: {
			service: "客服",
			send: "發送",
			inputtext: "輸入文字",
			online: "在線",
			Offline: "離線",
			profile: "個人簡介",
			cleartext: "確定要清空聊天記錄嗎？",
			received: "收到的贊",
			liked: "我點的贊",
			lv: "等級",
			EXP: "經驗值",
			share: "分享",
			forward: "來自：轉發",
			website: "來自：網站",
			picture: "圖片",
			photo: "相冊",
			dynamics: "TA的動態",
			TRANSLATORS: "翻譯",
			forward2: "確定轉發該帖子嗎？",
			delete: "確定刪除該帖子嗎？",
			delete2: "確定刪除該圖片嗎？",
			send2: "發布您的評論",
			from: "來自",
			tag: "標簽",
			personal: "個人資料",
			emote: "表情",
			video: "視頻",
			TA: "TA",
			untranslate: "不翻譯",
			text1: "不良信息是指含有色情、暴力、廣告或其他騷擾妳正常工作生活的內容",
			text2: "您要舉報的信息：",
			text3: "填寫您要舉報的原因：",
			text4: "請放心填寫，我們會保護您的隱私",
			fans: "我的粉絲",
		},
		btn: {
			chat: "聊天",
			clear: "清空",
			verify: "確認",
			cancel: "取消",
			clearCon: "清空內容",
			prompt: "不再提示",
			uncheck: "取關",
			close: "關閉",
			report: "舉報",
			publish: "發布",
			add: "添加",
			delete: "刪除",
			upload: '上傳',
			submit: '提交'
		},
		home: {
			vermicelli: "粉絲",
			dynamic: "動態",
			collect: "收藏",
			message: "私聊",
			visitor: "來訪記錄",
			service: "聯系客服",
			system: "系統消息",
			follow: "關注",
			like: "點贊",
			me: "@我",
			sendPost: "發帖子",
			ordinary: "普通會員 ",
			diamond: "鑽石會員",
			permanent: "永久會員",
		},
		index: {
			all: "全部",
			recommended: "推薦",
			send: "送TA",
			aite: "@TA",
			direct: "私聊",
		},
		post: {
			emote: "表情",
			video: "視頻",
			picture: "圖片",
			ta: "TA",
			untranslate: "不翻譯",
		},
		gift: {
			total: "全部禮物",
			mygift: "我的禮物",
			all: "全部",
			virtual: "虛擬禮物",
			accessory: "首飾",
			doll: "玩偶",
			flower: "鮮花",
			watch: "手表",
			details: "詳情",
			inventory: "庫存",
			specification: "規格",
			buy: "購買",
			text: "請輸入祝福語",
			quantity: "數量",
			anonymous: "匿名贈送",
			open: "公開贈送",
			private: "私下贈送",
			desc: "簡介"
		},
		upgrades: {
			membership: "升級會員",
			section: "部分功能",
			full: "全部功能",
			text: "您已經是永久會員了！",
			successfully: "升級成功~",
			text2: "金幣不足請先充值~",
			text3: "確定升級會員嗎？",
			text4: "去充值",
			permanent: "永久",
		},
		topup: {
			coin: "充值金幣",
			amount: "金額",
			other: "其他金額",
			information: "充值信息",
			text: "請輸入其他金額",
			text2: "充值記錄/贈送記錄",
			serial: "流水號",
			payee: "收款用戶",
			recharge: "充值方式",
			time: " 時間",
			status: "狀態",
		},
		setting: {
			information: "基本信息",
			translation: "購買翻譯包",
			privacy: "隱私設置",
			notification: "通知",
			blacklist: "用戶黑名單",
			security: "賬號安全",
			invitation: "郵箱邀請",
			invitation2: "鏈接邀請",
			avatar: "頭像",
			loginemail: "登錄郵箱",
			areaname: "地區名稱",
			addtag: "添加標簽",
			unmodifiable: "不可修改",
			package: "翻譯包",
			remaining: "剩余",
			character: "字符",
			text1: "選擇翻譯包套餐（字符） ",
			text2: "誰可以評論我的帖子",
			text3: "誰可以點贊我的帖子",
			text4: "誰可以收藏我的帖子",
			text5: "所有人（不包括黑名單用戶）",
			text6: "我關注的人",
			text7: "接收系統通知郵件：當收到@，評論，私信提醒時，設置是否接收系統發出的通知郵件以及接收郵件的周期 ",
			alluding: "提到我的",
			review: "評論",
			letter: "私信",
			reminder: "郵件提醒",
			receive: "接收系統郵件",
			receive2: "不接收系統郵件",
			never: "從不接收",
			daily: "每天接收",
			weekly: "每周接收",
			monthly: "每月接收",
			shift: "移出",
			text8: "被加入黑名單的用戶將無法關注妳，評論妳和查看妳的個人空間。如果妳已經關注TA，也會自動解除關系。",
			addblacklist: "添加黑名單",
			changepassword: "修改密碼",
			bound: "已綁定郵箱",
			original: "原始密碼",
			newpwd: "新密碼",
			text9: "妳可以通過郵箱邀請好友，好友成功注冊後將獲50點經驗",
			confirm: "確認邀請",
			invitation3: "邀請列表",
			text10: "請輸入郵箱"
		},
		othor: {
			online: "在線",
			Offline: "離線",
			gift: '贈送',
			giftHer: '贈送給',
			immediately: '立即贈送',
			Allread: '全部已讀',
			unread: '未讀',
			read: '已讀',
			text1: '有什麽新鮮事告訴大家~',
			input: '請輸入',
			trend: '動態詳情',
			system: '消息',
			myname: '我的',
			text: '我的金幣',
			btnall: '常用功能',
			save: '保存',
			mytrends: '我的動態',
			mysave: '我的收藏',
			mylove: '我的關注',
			member: '會員',
			record: '充值記錄',
			Purchase: '購買記錄',
			coin: '贈送金幣',
			Buynow: '立即購買',
			Invitefriends: '邀請好友',
			Copylink: '複制鏈接',
			completed: '已完成',
			Blacklist: '黑名單',
			text2: '請選擇金額',
			text3: '請完善充值信息',
			text4: '待審核',
			text5: '駁回',
			Liked: '點贊了',
			published: '發布的',
			my: '了我',
			my2: '我',
			text6: '復製鏈接',
			text7: '會員專享等你來拿',
			text8: '禮物商城',
			text9: '快來選取禮物送好友吧',
			text10: '接收者',
			mount: '總',
			type: '類型',
			money: '充值',
			gomoney: '立即充值',
			number: '其他數量',
			realname: '真實姓名',
			inputName: '請輸入真實姓名',
			realnum: '真實手機號',
			inputNum: '請輸入真實手機號',
			realEmial: '真實郵箱',
			inputEmial: '請輸入真實郵箱',
			butText: '購買信息',
			login1: '同一個世界相遇',
			and: '與',
			you: '你',
			sigin: '立即註冊',
			read2: '我已閱讀並同意',
			login2: '從世界的另一頭',
			sigintit: '註冊',
			login3: '相識',
			login4: '在此',
			login5: '我有賬號',
			loginbtn: '立即登錄',
			end: '完成',
			update: '確認修改',
			memberdesc: '您當前是',
			userinfo: '個人信息',
			userDesc: '請輸入個人簡介~',
			chatTit: '聊天中',
			chatTitlist: '聊天記錄',
			tishi: '請輸入有效內容',
			sendText: '贈送記錄',
			sendper: '請輸入贈送人',
			buyText: '可在購買記錄中查看',
			buySucc: '購買成功',
			select: '選擇',
			selected: '已選',
			page: '張',
			agreeBtn: '請勾選協議',
			senduser: '贈送用戶',
			code:'請輸入驗證碼',
			getCode:'驗證碼',
			sosoText:'發布成功 請等待審核',
			succTrends:'轉發成功',
			total :'共',
			article:'條',
			getmore:'查看更多',
			usdt:'已提交 請等待審核',
			upload:'上傳支付憑證',
			address:'地址',
			chinaText:'禁止輸入中文'
		}
	}
};